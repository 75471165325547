<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="4">
        <h1>Promociones activas</h1>
      </v-col>

      <v-col cols="9" md="7">
        <v-select
          v-model="selectedOperation"
          :items="operations"
          prepend-icon="fa-filter"
          label="Filtrar"
          item-text="text"
          item-value="value"
          aria-autocomplete="false"
          autocomplete="off"
          hide-details
          filled
          @change="getData"
          rounded
          :allow-overflow="false"
        >
        </v-select>
      </v-col>
      <v-col cols="1" class="text-right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="rgba(0, 0, 0, 0.6)"
              fab
              v-on="on"
              class="mr-1"
              @click="toExcel()"
              dark
              :elevation="0"
              :minHeight="56"
              :loading="loading"
            >
              <v-icon x-large dark>fa-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Exportar .CSV</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0 mt-5">
      <v-col cols="12">
        <v-data-table
          :headers="getHeaders"
          :items="data"
          :items-per-page="10"
          :search="$store.state.search"
          :loading="loading"
          item-key=".key"
          :sort-desc="[false, true]"
          :single-expand="true"
          fixed-header
          height="75vh"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.freeShippingProductsIsAvailable`]="{ item }">
            <v-switch
              :loading="loading"
              @change="handleFreeDelivery(item)"
              v-model="item.freeShippingProductsIsAvailable"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isDiscount`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                item.shortName
                  ? disableBusinessDiscount(item)
                  : disableProductDiscount(item)
              "
              v-model="item.isDiscount"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isFreeDelivery`]="{ item }">
            <v-switch
              :loading="loading"
              @change="disableProductFreeShipping(item)"
              v-model="item.isFreeDelivery"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isPromo`]="{ item }">
            <v-switch
              :loading="loading"
              @change="disableProductInPromo(item)"
              v-model="item.isPromo"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.discountMax`]="{ item }">
            <v-chip
              v-if="item.discountMax"
              class="ma-2"
              color="green"
              text-color="white"
            >
              {{ Math.round(item.discountMax) }} %
            </v-chip>
          </template>

          <template v-slot:[`item.price`]="{ item }">
            {{ item.price | currency }}
          </template>

          <template v-slot:[`item.isBusinessClosed`]="{ item }">
            <v-switch disabled v-model="item.isBusinessClosed"></v-switch>
          </template>

          <template v-slot:[`item.closed`]="{ item }">
            <v-switch disabled v-model="item.closed"></v-switch>
          </template>

          <template v-slot:[`item.discountPrice`]="{ item }">
            <p v-if="item.discountPrice">
              {{ item.discountPrice | currency }}
            </p>
          </template>

          <template v-slot:[`item.discountPercentage`]="{ item }">
            <v-chip
              v-if="item.isDiscount"
              class="ma-2"
              color="green"
              text-color="white"
            >
              {{ Math.round(100 - (item.discountPrice / item.price) * 100) }} %
            </v-chip>
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <v-avatar class="mt-5">
              <v-img
                v-if="item.logo && item.logo.original"
                :src="item.logo.original"
                :alt="item.shortName"
                contain
                :lazy-src="item.logo.loading"
              ></v-img>

              <v-img
                v-else
                :src="ochoLogoPlaceholder"
                :alt="item.shortName"
                :lazy-src="ochoLogoPlaceholder"
              ></v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="viewProducts(item)" small color="primary">
              Productos
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-if="selectedBusiness"
      v-model="modalViewProducts"
      max-width="80%"
    >
      <business-products
        @cancel="closeModal"
        :key="selectedBusiness['.key']"
        :business="selectedBusiness"
        :operation="selectedOperation"
      ></business-products>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import businessProducts from "./business-products";

export default {
  name: "promotions-details",
  mixins: [lottieSettings],
  components: {
    lottie: Lottie,
    businessProducts,
  },
  data() {
    return {
      loading: true,
      saving: false,
      countries: [],
      selectedCountry: "",
      selectedCity: "",
      data: [],
      businesses: [],
      selectedOperation: "",
      randomKey: 0,
      snackbar: false,
      snackbarText: "",
      modalViewProducts: false,
      queryResult: [],
      selectedBusiness: null,
      ochoLogoPlaceholder:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
      productsHeaders: [
        {
          value: "image",
        },
        {
          text: "Nombre del comercio",
          value: "businessName",
        },
        {
          text: "Nombre del producto",
          value: "name",
        },
        {
          text: "Precio original",
          value: "price",
        },
        {
          text: "Precio con descuento",
          value: "discountPrice",
        },
        {
          text: "Porcentaje de descuento",
          value: "discountPercentage",
        },
        {
          text: "Descuento activo",
          value: "isDiscount",
        },
      ],
      operations: [
        { text: "Comercios con envío gratis", value: 0 },
        { text: "Comercios con productos en descuentos", value: 1 },
        { text: "Productos con envío gratis", value: 2 },
        { text: "Productos con descuentos", value: 3 },
        { text: "Productos en promoción", value: 4 },
      ],
    };
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  computed: {
    getHeaders() {
      switch (this.selectedOperation) {
        case 0:
          return [
            {
              value: "image",
            },
            {
              text: "Nombre del comercio",
              value: "shortName",
            },
            // {
            //   text: "Cerrado",
            //   value: "closed",
            // },
            {
              text: "Envío gratis",
              value: "freeShippingProductsIsAvailable",
            },
            {
              value: "options",
              align: "end",
            },
          ];
        case 1:
          return [
            {
              value: "image",
            },
            {
              text: "Nombre del comercio",
              value: "shortName",
            },
            // {
            //   text: "Cerrado",
            //   value: "closed",
            // },
            {
              text: "Descuento máximo",
              value: "discountMax",
            },
            {
              text: "Descuento activo",
              value: "isDiscount",
            },
            {
              value: "options",
              align: "end",
            },
          ];
        case 2:
          return [
            {
              value: "image",
            },
            {
              text: "Nombre del comercio",
              value: "businessName",
            },
            {
              text: "Nombre del producto",
              value: "name",
            },
            {
              text: "Precio",
              value: "price",
            },
            {
              text: "Envío gratis",
              value: "isFreeDelivery",
            },
          ];
        case 3:
          return this.productsHeaders;
        case 4:
          return [
            {
              value: "image",
            },
            {
              text: "Nombre del comercio",
              value: "businessName",
            },
            {
              text: "Nombre del producto",
              value: "name",
            },
            {
              text: "Precio",
              value: "price",
            },
            {
              text: "Promoción activa",
              value: "isPromo",
            },
          ];
        default:
          return this.productsHeaders;
      }
    },
  },

  watch: {
    queryResult() {
      // query.get().then((response) => {
      this.data = [];
      this.queryResult.forEach((product) => {
        if (
          this.selectedOperation == 2 ||
          this.selectedOperation == 3 ||
          this.selectedOperation == 4
        ) {
          // let product = item.data();
          // product[".key"] = product.id;

          let searchBusiness = this.businesses.find((business) =>
            product.business.includes(business[".key"])
          );

          product.businessName = searchBusiness ? searchBusiness.shortName : "";

          product.logo = searchBusiness ? searchBusiness.logo : null;

          product.isBusinessActive = searchBusiness
            ? searchBusiness.active
            : false;
          product.isBusinessClosed = searchBusiness
            ? searchBusiness.closed
            : false;

          this.data.push(product);

          this.data = this.data.filter((item) => item.isBusinessActive);
        } else {
          let business = product;
          // business[".key"] = item.id;
          this.data.push(business);
        }
        this.loading = false;
      });
      // });
    },
  },
  methods: {
    viewProducts(item) {
      this.selectedBusiness = item;
      this.modalViewProducts = true;
    },

    async disableProductInPromo(item) {
      this.loading = true;
      await db
        .collection("products")
        .doc(item[".key"])
        .update({ isPromo: false });

      this.loading = false;
      this.snackbarText = "Promoción desactivada.";
      this.snackbar = true;
    },

    async disableBusinessDiscount(item) {
      console.log("Disable business discunt");
      let batchData = [];
      this.loading = true;
      let products = await db
        .collection("products")
        .where("business", "array-contains", item[".key"])
        .where("isDiscount", "==", true)
        .get();

      for (let i = 0; i < products.docs.length; i++) {
        const productId = products.docs[i].id;
        batchData.push({
          reference: db.collection("products").doc(productId),
          data: {
            isDiscount: false,
          },
        });
      }

      for (let i = 0; i < batchData.length; i = i + 490) {
        let batch = db.batch();

        let itemsToUpdate = batchData.slice(i, i + 490);

        itemsToUpdate.forEach((item) => {
          batch.update(item.reference, item.data);
        });

        await batch.commit();
        batch = null;
      }

      await db.collection("businesses").doc(item[".key"]).update({
        isDiscount: false,
      });

      this.loading = false;
      this.snackbarText = "Todos los descuentos desactivado.";
      this.snackbar = true;
    },

    async disableProductDiscount(item) {
      console.log("Disable product discunt");

      this.loading = true;

      let hasDiscount = await db
        .collection("products")
        .where("isDiscount", "==", true)
        .where("active", "==", true)
        .where("deleted", "==", false)
        .where("business", "array-contains", item.business[0])
        .get();

      if (hasDiscount.size <= 1) {
        await db.collection("businesses").doc(item.business[0]).update({
          isDiscount: false,
        });
      }

      await db.collection("products").doc(item[".key"]).update({
        isDiscount: false,
      });

      this.loading = false;
      this.snackbarText = "Envío gratis desactivado.";
      this.snackbar = true;
    },

    async disableProductFreeShipping(item) {
      this.loading = true;

      let hasFreeDelivery = await db
        .collection("products")
        .where("isFreeDelivery", "==", true)
        .where("active", "==", true)
        .where("deleted", "==", false)
        .where("business", "array-contains", item.business[0])
        .get();

      if (hasFreeDelivery.size <= 1) {
        await db.collection("businesses").doc(item.business[0]).update({
          isFreeDelivery: false,
          freeShippingProductsIsAvailable: false,
        });
      }

      await db.collection("products").doc(item[".key"]).update({
        isFreeDelivery: false,
      });

      this.loading = false;
      this.snackbarText = "Envío gratis desactivado.";
      this.snackbar = true;
    },

    async handleFreeDelivery(item) {
      let batchData = [];
      this.loading = true;
      let products = await db
        .collection("products")
        .where("business", "array-contains", item[".key"])
        .where("isFreeDelivery", "==", true)
        .get();

      for (let i = 0; i < products.docs.length; i++) {
        const productId = products.docs[i].id;
        batchData.push({
          reference: db.collection("products").doc(productId),
          data: {
            isFreeDelivery: false,
          },
        });
      }

      for (let i = 0; i < batchData.length; i = i + 490) {
        let batch = db.batch();
        let itemsToUpdate = batchData.slice(i, i + 490);
        itemsToUpdate.forEach((item) => {
          batch.update(item.reference, item.data);
        });
        await batch.commit();
        batch = null;
      }

      await db.collection("businesses").doc(item[".key"]).update({
        isFreeDelivery: false,
        freeShippingProductsIsAvailable: false,
      });

      this.loading = false;
      this.snackbarText = "Envío gratis desactivado.";
      this.snackbar = true;
    },
    closeModal() {
      this.modalViewProducts = false;
      this.selectedBusiness = null;
    },
    async getData() {
      this.data = [];
      this.loading = true;
      let query = "";

      switch (this.selectedOperation) {
        case 0:
          query = db
            .collection("businesses")
            .where("freeShippingProductsIsAvailable", "==", true)
            .where("deleted", "==", false)
            .where("active", "==", true);
          break;
        case 1:
          query = db
            .collection("businesses")
            .where("isDiscount", "==", true)
            .where("deleted", "==", false)
            .where("active", "==", true);
          break;
        case 2:
          query = db
            .collection("products")
            .where("isFreeDelivery", "==", true)
            .where("deleted", "==", false)
            .where("addon", "==", false);
          break;
        case 3:
          query = db
            .collection("products")
            .where("isDiscount", "==", true)
            .where("deleted", "==", false)
            .where("addon", "==", false);
          break;
        case 4:
          query = db
            .collection("products")
            .where("isPromo", "==", true)
            .where("deleted", "==", false)
            .where("addon", "==", false);
          break;
      }

      if (
        this.selectedOperation == 2 ||
        this.selectedOperation == 3 ||
        this.selectedOperation == 4
      ) {
        await this.getBusinesses();
      }

      await this.$binding("queryResult", query);
      this.loading = false;

      // query.get().then((response) => {
      //   response.forEach((item) => {
      //     if (
      //       this.selectedOperation == 2 ||
      //       this.selectedOperation == 3 ||
      //       this.selectedOperation == 4
      //     ) {
      //       let product = item.data();
      //       product[".key"] = item.id;
      //       let searchBusiness = this.businesses.find((business) =>
      //         product.business.includes(business[".key"])
      //       );
      //       (product.businessName = searchBusiness
      //         ? searchBusiness.shortName
      //         : ""),
      //         (product.logo = searchBusiness ? searchBusiness.logo : null);
      //       product.isBusinessActive = searchBusiness
      //         ? searchBusiness.active
      //         : false;
      //       product.isBusinessClosed = searchBusiness
      //         ? searchBusiness.closed
      //         : false;
      //       this.data.push(product);

      //       this.data = this.data.filter((item) => item.isBusinessActive);
      //     } else {
      //       let business = item.data();
      //       business[".key"] = item.id;
      //       this.data.push(business);
      //     }
      //     this.loading = false;
      //   });
      // });
    },
    getBusinesses() {
      return new Promise((resolve) => {
        if (this.businesses.length > 0) return resolve(true);
        db.collection("businesses")
          .where("deleted", "==", false)
          .get()
          .then((response) => {
            response.forEach((item) => {
              let business = item.data();
              business[".key"] = item.id;
              this.businesses.push(business);
            });
            resolve(true);
          });
      });
    },
    toExcel() {
      let operation = this.operations.find(
        (item) => item.value == this.selectedOperation
      );
      let date = new Date();
      const filename = `${operation.text} ${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}.csv`;
      const fields = [];

      let headers = this.getHeaders;
      headers = headers.filter(
        (item) => item.value != "options" && item.value != "image"
      );

      headers.forEach((item) => {
        fields.push({
          label: item.text,
          value: item.value,
        });
      });

      const json2csvParser = new Parser({
        fields,
        excelStrings: true,
        withBOM: true,
      });

      let csv = json2csvParser.parse(this.data);
      csv = csv.replaceAll("true", "Si").replaceAll("false", "No");

      let blob = new Blob([csv], { type: "text/csv" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.loading = false;
  },
};
</script>

<style>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
