<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          Productos de {{ business.shortName }} con
          {{ operation == 0 ? "envío gratis" : "descuentos" }}
        </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-data-table
            :headers="headers"
            :items="products"
            :items-per-page="10"
            :search="$store.state.search"
            :loading="loading"
            :sort-desc="[false, true]"
            :single-expand="true"
            fixed-header
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100, 250],
            }"
          >
            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <v-avatar class="mt-4">
                <v-img
                  v-if="item.images && item.images.length"
                  :src="item.images[0]"
                  contain
                ></v-img>

                <v-img v-else :src="ochoLogoPlaceholder"></v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ item.price | currency }}
            </template>

            <template v-slot:[`item.isFreeDelivery`]="{ item }">
              <v-switch
                :loading="loading"
                @change="disableProductFreeShipping(item)"
                v-model="item.isFreeDelivery"
              >
              </v-switch>
            </template>

            <template v-slot:[`item.isDiscount`]="{ item }">
              <v-switch
                :loading="loading"
                @change="disableProductDiscount(item)"
                v-model="item.isDiscount"
              >
              </v-switch>
            </template>

            <template v-slot:[`item.shipping`]="{}">
              <v-chip class="ma-2" color="green" text-color="white">
                Envío gratis
              </v-chip>
            </template>
            <template v-slot:[`item.discountPrice`]="{ item }">
              <p v-if="item.discountPrice">
                {{ item.discountPrice | currency }}
              </p>
            </template>

            <template v-slot:[`item.discountPercentage`]="{ item }">
              <v-chip
                v-if="item.isDiscount"
                class="ma-2"
                color="green"
                text-color="white"
              >
                {{ Math.round(100 - (item.discountPrice / item.price) * 100) }}
                %
              </v-chip>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <!-- <v-card-actions v-if="!loading">
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row align="center" justify="end">
                <v-btn
                  @click="$emit('cancel')"
                  class="save-btn mt-4"
                  color="primary"
                >
                  Salir
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions> -->
    </v-card>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

export default {
  name: "business-products",
  mixins: [lottieSettings],
  props: ["business", "operation"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      products: [],
      ochoLogoPlaceholder:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
      headers: [
        {
          value: "image",
        },
        {
          text: "Nombre del producto",
          value: "name",
        },
        {
          text: "Precio original",
          value: "price",
        },
        {
          text: "Precio con descuento",
          value: "discountPrice",
        },
        {
          text: "Porcentaje de descuento",
          value: "discountPercentage",
          align: "center",
        },

        {
          text: "Descuento activo",
          value: "isDiscount",
          align: "center",
        },
      ],
    };
  },

  methods: {
    async disableProductDiscount(item) {
      console.log("Disable product discunt");

      this.loading = true;

      let hasDiscount = await db
        .collection("products")
        .where("isDiscount", "==", true)
        .where("active", "==", true)
        .where("deleted", "==", false)
        .where("business", "array-contains", item.business[0])
        .get();

      if (hasDiscount.size <= 1) {
        await db.collection("businesses").doc(item.business[0]).update({
          isDiscount: false,
        });
      }

      await db.collection("products").doc(item[".key"]).update({
        isDiscount: false,
      });

      this.loading = false;
      this.snackbarText = "Envío gratis desactivado.";
      this.snackbar = true;
    },

    async disableProductFreeShipping(item) {
      this.loading = true;

      let hasFreeDelivery = await db
        .collection("products")
        .where("isFreeDelivery", "==", true)
        .where("active", "==", true)
        .where("deleted", "==", false)
        .where("business", "array-contains", item.business[0])
        .get();

      if (hasFreeDelivery.size <= 1) {
        await db.collection("businesses").doc(item.business[0]).update({
          isFreeDelivery: false,
          freeShippingProductsIsAvailable: false,
        });
      }

      await db.collection("products").doc(item[".key"]).update({
        isFreeDelivery: false,
      });

      this.loading = false;
      this.snackbarText = "Envío gratis desactivado.";
      this.snackbar = true;
    },
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },

  async mounted() {
    this.products = [];
    let productsQuery = "";

    if (this.operation == 0) {
      this.headers = [
        {
          value: "image",
        },
        {
          text: "Nombre del producto",
          value: "name",
        },
        {
          text: "Envío",
          value: "shipping",
        },

        {
          text: "Precio",
          value: "price",
          align: "center",
        },
        {
          text: "Envío gratis activo",
          value: "isFreeDelivery",
        },
      ];
      productsQuery = db
        .collection("products")
        .where("business", "array-contains", this.business[".key"])
        .where("isFreeDelivery", "==", true)
        .where("deleted", "==", false)
        .where("addon", "==", false);
    } else {
      productsQuery = db
        .collection("products")
        .where("business", "array-contains", this.business[".key"])
        .where("isDiscount", "==", true)
        .where("deleted", "==", false)
        .where("addon", "==", false);
    }

    // productsQuery.get().then((response) => {
    //   response.forEach((item) => {
    //     let product = item.data();
    //     product[".key"] = item.id;
    //     this.products.push(product);
    //   });
    //   this.loading = false;
    // });

    await this.$binding("products", productsQuery);
    this.loading = false;
  },
};
</script>
